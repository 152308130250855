import React from "react";
import css from "./Lightbox.module.scss";
import path from "../../../util/path";
import closeIcon from "../../../assets/icons/close-white.svg"

const Lightbox = (props) => {
  const { open, link, onClose } = props;
  return (
    open && (
      <div className={css.lbContainer}>
        <div className={css.lbClose} onClick={onClose}>
          <img src={closeIcon} alt=""/>
        </div>
        <div className={css.lbPhoto}>
          <img src={path + link} alt="" />
        </div>
      </div>
    )
  );
};

export default Lightbox;
