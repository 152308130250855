import * as actionTypes from "../actions/actionTypes";

const initialState = {
  editCompleted: false,
  editLoad: false,
  user: null,
  changePasswordLoad: false,
  changePasswordCompleted: false,
  changePasswordError: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        user: action.user,
      };

    case actionTypes.EDIT_USER_LOAD:
      return {
        ...state,
        editLoad: true,
      };

    case actionTypes.EDIT_USER_SUCCESS:
      return {
        ...state,
        editLoad: false,
        editCompleted: true,
        user: action.user,
      };

    case actionTypes.CLEAR_USER_COMPLETED:
      return {
        ...state,
        editCompleted: false,
      };

    case actionTypes.CHANGE_PASSWORD_LOAD:
      return {
        ...state,
        changePasswordLoad: true,
      };

    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordLoad: false,
        changePasswordCompleted: true,
        changePasswordError: null,
      };

    case actionTypes.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordLoad: false,
        changePasswordError: action.error,
      };

    case actionTypes.CLEAR_CHANGE_PASSWORD_COMPLETED:
      return {
        ...state,
        changePasswordCompleted: false,
      };

    default:
      return state;
  }
};

export default userReducer;
