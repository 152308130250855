import * as actionTypes from "../actions/actionTypes";

const initialState = {
  featured: null,
  singleProduct: null,
  singleLoading: false,
  relatedProds: null,
  home: null,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FEATURED_SUCCESS:
      return {
        ...state,
        featured: action.featured,
      };

    case actionTypes.GET_SINGLE_PRODUCT_LOAD:
      return {
        ...state,
        singleLoading: true,
      };

    case actionTypes.GET_SINGLE_PRODUCT_SUCCESS:
      return {
        ...state,
        singleLoading: false,
        singleProduct: action.product,
        relatedProds: action.related
      };

    case actionTypes.GET_HOME_PRODUCTS_SUCCESS:
      return {
        ...state,
        home: action.data,
      };

    default:
      return state;
  }
};

export default productReducer;
