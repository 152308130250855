import * as actionTypes from "./actionTypes";
import { generalError } from "./index";
import axios from "axios";

export const getTables = () => {
  return (dispatch) => {
    axios
      .get("/tables")
      .then((res) => {
        dispatch(getTableSucces(res.data));
      })
      .catch((err) => {
        dispatch(generalError(err.response.data.message));
      });
  };
};

export const getTableSucces = (data) => {
  return {
    type: actionTypes.GET_TABLE_SUCCESS,
    tables: data,
  };
};
