import * as actionTypes from "./actionTypes";
import axios from "axios";

export const login = (values) => {
  return (dispatch) => {
    dispatch(loginLoad());
    axios
      .post("/login", { ...values })
      .then((res) => {
        const expirationDate = new Date(
          new Date().getTime() + res.data.expiresIn * 1000
        );

        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userId", res.data.user_id);
        localStorage.setItem("wishlistId", res.data.wishlistId);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("expiresIn", res.data.expiresIn);
        dispatch(loginSuccessStartSession(res.data));
      })
      .catch((err) => {
        dispatch(loginError(err.response.data.message));
      });
  };
};

export const loginLoad = () => {
  return {
    type: actionTypes.LOGIN_LOAD,
  };
};

export const loginSuccessStartSession = (data) => {
  return (dispatch) => {
    dispatch(checkTimeout(data.expiresIn - 3));
    dispatch(loginSuccess(data));
  };
};

export const loginSuccess = (data) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    token: data.token,
    user: data.user_id,
    wishlist: data.wishlistId,
  };
};

export const loginError = (err) => {
  return {
    type: actionTypes.LOGIN_ERROR,
    error: err,
  };
};

export const checkTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      const sWishlistId = localStorage.getItem("wishlistId");
      const sUserId = localStorage.getItem("userId");
      const sRefreshToken = localStorage.getItem("refreshToken");

      if (sRefreshToken) {
        dispatch(refreshToken(sRefreshToken, sUserId, sWishlistId));
      }
    }, expirationTime * 1000);
  };
};

export const refreshToken = (rToken, userId, wishlistId) => {
  return (dispatch) => {
    axios
      .post("/refreshToken", { rToken, userId })
      .then((res) => {
        const expirationDate = new Date(
          new Date().getTime() + res.data.expiresIn * 1000
        );

        localStorage.setItem("token", res.data.token);
        localStorage.setItem("expirationDate", expirationDate);

        dispatch(checkTimeout(res.data.expiresIn - 3));
        dispatch(refreshTokenSuccess(res.data.token, userId, wishlistId));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const refreshTokenSuccess = (token, user, wishlist) => {
  return {
    type: actionTypes.REFRESH_TOKEN_SUCCESS,
    token: token,
    user: user,
    wishlist: wishlist,
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("expiresIn");
  localStorage.removeItem("userId");
  localStorage.removeItem("wishlistId");

  return {
    type: actionTypes.LOGOUT,
  };
};

export const lostPassword = (value) => {
  return (dispatch) => {
    dispatch(lostPasswordLoad());
    axios
      .post("/lost-password", { ...value })
      .then((res) => {
        dispatch(lostPasswordSuccess());
      })
      .catch((err) => {
        dispatch(lostPasswordError(err.response.data.message));
      });
  };
};

export const lostPasswordLoad = () => {
  return {
    type: actionTypes.LOST_PASSWORD_LOAD,
  };
};

export const lostPasswordSuccess = () => {
  return {
    type: actionTypes.LOST_PASSWORD_SUCCESS,
  };
};

export const lostPasswordError = (error) => {
  return {
    type: actionTypes.LOST_PASSWORD_ERROR,
    error: error,
  };
};

export const clearLostPasswordCompleted = () => {
  return {
    type: actionTypes.CLEAR_LOST_PASSWORD_COMPLETED,
  };
};
