import * as actionTypes from "./actionTypes";
import axios from "axios";

export const newUserRequest = (values, language) => {
  let fLanguage = "en";

  if (language === "it" || language === "it-IT") {
    fLanguage = "it";
  }

  return (dispatch) => {
    dispatch(newUserRequestLoad());
    axios
      .post("/user/new-request", {
        ...values,
        language: fLanguage,
      })
      .then((res) => {
        dispatch(newUserRequestSuccess());
      })
      .catch((err) => {
        dispatch(newUserRequestError(err.response.data.errors));
      });
  };
};

export const newUserRequestLoad = () => {
  return {
    type: actionTypes.NEW_USER_REQUEST_LOAD,
  };
};

export const newUserRequestSuccess = () => {
  return {
    type: actionTypes.NEW_USER_REQUEST_SUCCESS,
  };
};

export const newUserRequestError = (err) => {
  return {
    type: actionTypes.NEW_USER_REQUEST_ERROR,
    errors: err,
  };
};

export const clearUserRequest = () => {
  return {
    type: actionTypes.CLEAR_USER_REQUEST,
  };
};
