import React from "react";
import css from "./Footer.module.scss";
import logo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import i18next from "i18next";

const Footer = () => {
  return (
    <footer className={css.footer}>
      <div className="container">
        <div className={css.row}>
          <div className={css.col}>
            <img className={css.logo} src={logo} alt="" />
          </div>
          <div className={css.col}>
            <ul>
              <li className={css.link}>
                <Link to="/">Torna al sito</Link>
              </li>
              <li className={css.link}>
                <a
                  href="https://www.attilioimperiali.it/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy policy
                </a>
              </li>
              <li className={css.link}>
                <Link to="/">Impostazioni cookie</Link>
              </li>
            </ul>
          </div>
          <div className={css.col}>
            <ul>
              <li className={css.link}>
                <span>Tel: +031 491156</span>
              </li>
              <li className={css.link}>
                <span>P.IVA: 03815770130</span>
              </li>
            </ul>
          </div>
          <div className={css.col}>
            <span onClick={() => i18next.changeLanguage("en")}>English</span>
            <span onClick={() => i18next.changeLanguage("it")}>Italiano</span>
          </div>
        </div>
        <div className={css.row}>
          <div className={css.sub}>
            <div className={css.col}>
              <p className={css.grey}>
                COPYRIGHT ©2020 SERIKOS 1984 S.R.L. Via Edoardo Stucchi, 18,
                22075 Lurate Caccivio CO
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
