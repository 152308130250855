import React from "react";
import css from "./ErrorModal.module.scss";

const ErrorModal = (props) => {
  const { open, message, onClose } = props;

  if (open) {
    return (
      <div className={css.eModal}>
        <div className={css.eModalContent}>
          <div className={css.eModalClose} onClick={onClose}>
            X
          </div>
          <span>{message}</span>
        </div>
      </div>
    );
  } else return null;
};

export default ErrorModal;
