import React from "react";
import css from "./Home.module.scss";
import { useSelector } from "react-redux";
import FeaturedProducts from "../../components/FeaturedProducts/FeaturedProducts";
import HomeProducts from "../../components/HomeProducts/HomeProducts";

const Home = () => {
  const featured = useSelector((store) => store.product.featured);
  const homeProducts = useSelector((store) => store.product.home);

  return (
    <div className={css.home}>
      <div className="container">
        <FeaturedProducts products={featured} />
        <HomeProducts lists={homeProducts} />
      </div>
    </div>
  );
};

export default Home;
