import React from "react";
import { useSelector } from "react-redux";
import { Route, useLocation, useHistory } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuth = useSelector((store) => store.auth.token);

  const history = useHistory();
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          <Component {...props} />
        ) : (
          history.push({ pathname: "/login", from: location.pathname })
        )
      }
    />
  );
};

export default PrivateRoute;
