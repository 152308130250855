import * as actionTypes from "../actions/actionTypes";

const initialState = {
  compositions: null,
  collections: null,
  moods: null,
  applications: null,
};

const tableReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TABLE_SUCCESS:
      return {
        collections: action.tables.collections,
        compositions: action.tables.compositions,
        moods: action.tables.moods,
        applications: action.tables.applications,
      };

    default:
      return state;
  }
};

export default tableReducers;
