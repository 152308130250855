import * as actionTypes from "./actionTypes";

export const clearFilters = () => {
  return {
    type: actionTypes.CLEAR_SEARCH_FILTERS,
  };
};

export const setMoodValue = (data) => {
  return {
    type: actionTypes.SET_MOOD_VALUE,
    value: data,
  };
};

export const setApplicationValue = (data) => {
  return {
    type: actionTypes.SET_APPLICATION_VALUE,
    value: data,
  };
};

export const setCompositionValue = (data) => {
  return {
    type: actionTypes.SET_COMPOSITION_VALUE,
    value: data,
  };
};

export const setCollectionValue = (data) => {
  return {
    type: actionTypes.SET_COLLECTION_VALUE,
    value: data,
  };
};

export const setSustainableValue = (data) => {
  return {
    type: actionTypes.SET_SUSTAINABLE_VALUE,
    value: data,
  };
};
