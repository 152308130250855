import React from "react";
import css from "./Modal.module.scss";

const Modal = (props) => {
  const { open, children } = props;

  if (open) {
    return (
      <div className={css.modal}>
        <div className={css.modalContent}>{children}</div>
      </div>
    );
  } else return null;
};

export default Modal;
