import React from "react";
import css from "./Login.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as actions from "../../store/actions";
import Button from "../../components/UI/Button/Button";
import { useTranslation } from "react-i18next";

const Login = (props) => {
  const { t } = useTranslation();
  const loading = useSelector((store) => store.auth.loading);
  const error = useSelector((store) => store.auth.error);
  const token = useSelector((store) => store.auth.token);
  const dispatch = useDispatch();

  const { handleSubmit, register, errors } = useForm();
  const onSubmit = (values) => {
    dispatch(actions.login(values));
  };

  return (
    <>
      {token ? (
        <Redirect to={props.location.from || "/"} />
      ) : (
        <div className="container">
          <div className={css.login}>
            <p>
              {t("login.no account")}{" "}
              <Link className="simple-link" to="/registration">
                {t("login.click for register")}
              </Link>
            </p>
            <h1>{t("general.account")}</h1>

            <form onSubmit={handleSubmit(onSubmit)} className={css.row}>
              <div className={css.col}>
                <label htmlFor="email">{t("forms.email")}</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  ref={register({
                    required: t("forms.email required"),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t("forms.email format"),
                    },
                  })}
                />
                <span className={css.error}>{errors?.email?.message}</span>
              </div>
              <div className={css.col}>
                <label htmlFor="password">{t("forms.password")}</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  ref={register({
                    required: t("forms.password required"),
                  })}
                />
                <span className={css.error}>{errors?.password?.message}</span>
              </div>
              <span className={css.error}>{error && error}</span>
              <div className={css.lostPassword}>
                <Link to="lost-password">{t("login.lost password?")}</Link>
              </div>
              <Button text={"Login"} load={loading} />
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
