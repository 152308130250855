import * as actionTypes from "./actionTypes";
import { generalError } from "./index";
import axios from "axios";

export const getUserInfo = (userId) => {
  return (dispatch) => {
    axios
      .get(`/user/${userId}`)
      .then((res) => {
        dispatch(getUserInfoSuccess(res.data));
      })
      .catch((err) => {
        dispatch(generalError(err.response.data.message));
      });
  };
};

export const getUserInfoSuccess = (data) => {
  return {
    type: actionTypes.GET_USER_INFO_SUCCESS,
    user: data,
  };
};

export const editUser = (userId, values) => {
  return (dispatch) => {
    dispatch(editUserLoad());
    axios
      .put(`/user/${userId}/edit`, { ...values })
      .then((res) => {
        dispatch(editUserSuccess(res.data.user));
      })
      .catch((err) => {
        dispatch(generalError(err.response.data.message));
      });
  };
};

export const editUserLoad = () => {
  return {
    type: actionTypes.EDIT_USER_LOAD,
  };
};

export const editUserSuccess = (user) => {
  return {
    type: actionTypes.EDIT_USER_SUCCESS,
    user: user,
  };
};

export const clearUserCompleted = () => {
  return {
    type: actionTypes.CLEAR_USER_COMPLETED,
  };
};

export const changePassword = (values) => {
  return (dispatch) => {
    dispatch(changePasswordLoad());
    axios
      .post(`/user/changepassword`, { ...values })
      .then((res) => {
        console.log(res);
        dispatch(changePasswordSuccess());
      })
      .catch((err) => {
        dispatch(changePasswordError(err.response.data.message));
      });
  };
};

export const changePasswordLoad = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_LOAD,
  };
};

export const changePasswordSuccess = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_SUCCESS,
  };
};

export const changePasswordError = (error) => {
  return {
    type: actionTypes.CHANGE_PASSWORD_ERROR,
    error,
  };
};

export const clearChangePasswordCompleted = () => {
  return {
    type: actionTypes.CLEAR_CHANGE_PASSWORD_COMPLETED,
  };
};
