import React from "react";
import Button from "../../components/UI/Button/Button";
import css from "./LostPassword.module.scss";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/";
import Modal from "../../components/UI/Modal/Modal";
import { useHistory } from "react-router";

const LostPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector((store) => store.auth.lostPasswordLoad);
  const error = useSelector((store) => store.auth.lostPasswordError);
  const completed = useSelector((store) => store.auth.lostPasswordCompleted);

  const { handleSubmit, register, errors } = useForm();

  const onSubmit = (value) => {
    dispatch(actions.lostPassword(value));
  };

  function clearModalCompleted() {
    dispatch(actions.clearLostPasswordCompleted());
    history.push("/login");
  }

  return (
    <div className="container">
      <div className={css.lostPassword}>
        <p>
          {t("login.no account")}{" "}
          <Link className="simple-link" to="/registration">
            {t("login.click for register")}
          </Link>
        </p>
        <h1>{t("login.lost password?")}</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={css.row}>
            <div className={css.col}>
              <label htmlFor="email">{t("forms.email")}</label>
              <input
                type="text"
                id="email"
                name="email"
                ref={register({
                  required: t("forms.email required"),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("forms.email format"),
                  },
                })}
              />
              <span className={css.error}>{errors?.email?.message}</span>
              {error && <span className={css.error}>{error}</span>}
            </div>
          </div>
          <Button text={t("login.send")} load={loading} />
        </form>
      </div>

      <Modal open={completed}>
        <div className={css.modalContent}>
          <h3>{t("login.lost password confirm")}</h3>
          <p>{t("login.password sended")}</p>
          <Button
            fullwidth
            text={t("general.ok")}
            onClick={() => clearModalCompleted()}
          />
        </div>
      </Modal>
    </div>
  );
};

export default LostPassword;
