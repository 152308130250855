import * as actionTypes from "./actionTypes";

export const generalError = (message) => {
  return {
    type: actionTypes.GENERAL_ERROR,
    message: message,
  };
};

export const clearGeneralError = () => {
  return {
    type: actionTypes.CLEAR_GENERAL_ERROR,
  };
};
