import * as actionTypes from "./actionTypes";
import { generalError } from "./index";
import axios from "axios";

export const getFeaturedProducts = () => {
  return (dispatch) => {
    axios
      .get("/products/featured")
      .then((res) => {
        dispatch(getFeaturedSuccess(res.data.products));
      })
      .catch((err) => {
        dispatch(generalError(err.response.data.message));
      });
  };
};

export const getFeaturedSuccess = (data) => {
  return {
    type: actionTypes.GET_FEATURED_SUCCESS,
    featured: data,
  };
};

export const getHomeProducts = () => {
  return (dispatch) => {
    axios
      .get("/products/home")
      .then((res) => {
        dispatch(getHomeProductSuccess(res.data));
      })
      .catch((err) => {
        dispatch(generalError(err.response.data.message));
      });
  };
};

export const getHomeProductSuccess = (data) => {
  return {
    type: actionTypes.GET_HOME_PRODUCTS_SUCCESS,
    data: data,
  };
};

export const getSingleProduct = (prodUrl, lang) => {
  return (dispatch) => {
    dispatch(getSingleProductLoad());
    axios
      .get(`/product/${prodUrl}`)
      .then((res) => {
        dispatch(getSingleProductSuccess(res.data));
      })
      .catch((err) => {
        dispatch(generalError(err.response.data.message));
      });
  };
};

export const getSingleProductLoad = () => {
  return {
    type: actionTypes.GET_SINGLE_PRODUCT_LOAD,
  };
};

export const getSingleProductSuccess = (data) => {
  return {
    type: actionTypes.GET_SINGLE_PRODUCT_SUCCESS,
    product: data.product,
    related: data.related
  };
};
