import React, { useState } from "react";
import css from "./SidebarBox.module.scss";
import chevron from "../../assets/icons/chevron.svg";

const SidebarBox = (props) => {
  const { children, title } = props;

  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className={css.box}>
      <div className={css.boxTitle}>
        <h5>{title}</h5>
        <div className={css.btnToggle} onClick={() => setIsOpen(!isOpen)}>
          <img src={chevron} alt="" />
        </div>
      </div>
      {isOpen && <div className={css.boxBody}>{children}</div>}
    </div>
  );
};

export default SidebarBox;
