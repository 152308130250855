import * as actionTypes from "./actionTypes";
import axios from "axios";
import { generalError } from "./index";
import { removeFromWishlistSuccess } from "../actions/wishlist";

export const newOrder = (userId, products, wishId, shipInfo, language) => {
  const finalProds = [];
  products.forEach((p) => {
    finalProds.push({ id: p.id });
  });

  let fLanguage = "en";
  if (language === "it" || language === "it-IT") {
    fLanguage = "it";
  }

  return (dispatch) => {
    axios
      .post("/order/new", {
        userId,
        products: finalProds,
        wishId,
        shipInfo,
        language: fLanguage,
      })
      .then((res) => {
        dispatch(newOrderSuccess());
        dispatch(removeFromWishlistSuccess(res.data.wishlist));
      })
      .catch((err) => {
        dispatch(generalError(err.response.data.message));
      });
  };
};

export const newOrderSuccess = () => {
  return {
    type: actionTypes.NEW_ORDER_SUCCESS,
  };
};

export const clearOrderStatus = () => {
  return {
    type: actionTypes.CLEAR_ORDER_STATUS,
  };
};
