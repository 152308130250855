import * as actionTypes from "../actions/actionTypes";

const initialState = {
  completed: false,
  errors: null,
  loading: false,
};

const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NEW_USER_REQUEST_LOAD:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.NEW_USER_REQUEST_SUCCESS:
      return {
        loading: false,
        errors: null,
        completed: true,
      };

    case actionTypes.NEW_USER_REQUEST_ERROR:
      return {
        ...state,
        errors: action.errors,
        loading: false,
      };

    case actionTypes.CLEAR_USER_REQUEST:
      return {
        completed: false,
        errors: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default registrationReducer;
